import * as React from "react";

export function Lines(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={46} height={24} fill="none" {...props}>
      <path fill="#C7DB4E" d="M46 24h-4V0h4z" />
      <path fill="#82CA69" d="M32 24h-4V0h4z" />
      <path fill="#38E2A5" d="M18 24h-4V0h4z" />
      <path fill="#38B9E2" d="M4 24H0V0h4z" />
    </svg>
  );
}

export function TopLines(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={48} height={36} fill="none" {...props}>
      <path fill="#C7DB4E" d="M47 36h-4V5h4z" />
      <path fill="#82CA69" d="M33 36h-4V5h4z" />
      <path fill="#38E2A5" d="M19 36h-4V5h4z" />
      <path fill="#38B9E2" d="M5 36H1V5h4z" />
      <circle
        cx={45}
        cy={3}
        r={2}
        fill="#fff"
        stroke="#C7DB4E"
        strokeWidth={2}
      />
      <circle
        cx={31}
        cy={3}
        r={2}
        fill="#fff"
        stroke="#82CA69"
        strokeWidth={2}
      />
      <circle
        cx={17}
        cy={3}
        r={2}
        fill="#fff"
        stroke="#38E2A5"
        strokeWidth={2}
      />
      <circle
        cx={3}
        cy={3}
        r={2}
        fill="#fff"
        stroke="#38B9E2"
        strokeWidth={2}
      />
    </svg>
  );
}
