import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Footer from "../components/FooterSection";
import Breadcrumb from "../components/Breadcrumb";
import Button from "../ui-components/Button";
import BannerWrapper from "../ui-components/BannerWrapper";
import { TopLines, Lines } from "../icons/CareerLines";
import { graphql, Link, useStaticQuery } from "gatsby";
import { CareersDataQuery } from "../../graphqlTypes";
import { DeepNonNullable } from "ts-essentials";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { mdxBody } from "@utils/mdxUtils";

const JopPositionRow = ({
  slug,
  title,
  type,
  location,
  startDate,
}: DeepNonNullable<CareersDataQuery>["strapiCareerspage"]["OpenPosition"]["job_positions"][number]) => (
  <Link to={"/careers/" + slug + "/"}>
    <div className="w-full p-6 bg-gray-200 rounded-3xl shadow-lg">
      <h3>{title}</h3>
      <div className="mt-4 flex flex-col sm:flex-row flex-wrap gap-4 sm:gap-12">
        <div className="flex flex-row gap-1">
          <h3 className="opacity-80">Time:</h3>
          <h3 className="opacity-60">
            {type == "fulltime" ? "Full-time" : "Part-time"}
          </h3>
        </div>
        <div className="flex flex-row gap-1">
          <h3 className="opacity-80">Location:</h3>
          <h3 className="opacity-60">{location}</h3>
        </div>
        <div className="flex flex-row gap-1">
          <h3 className="opacity-80">Start Date:</h3>
          <h3 className="opacity-60">{startDate}</h3>
        </div>
      </div>
    </div>
  </Link>
);

const CareersPage = () => {
  const data = useStaticQuery<DeepNonNullable<CareersDataQuery>>(graphql`
    query CareersData {
      strapiCareerspage {
        title
        subtitle
        pageTitle
        OpenPosition {
          job_positions {
            title
            type
            description
            location
            startDate
            contact
            slug
          }
        }
        textblock {
          title
          subtitle
        }
        # Banner Section
        childStrapiCareerspageBannerTitle {
          childMdx {
            body
          }
        }
        banner {
          title
          text
        }
      }
    }
  `);

  const {
    pageTitle,
    title,
    subtitle,
    OpenPosition,
    banner,
    textblock,
    childStrapiCareerspageBannerTitle: bannerTitle,
  } = data.strapiCareerspage;

  return (
    <Layout>
      <Seo title={pageTitle} />
      <div className="div-block flex flex-col max-w-7xl mx-5 xl:mx-auto mt-4 mb-4">
        <Breadcrumb title={title} />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-start-1 col-end-6 md:col-end-4">
            <h1 className="mt-16 text-white mb-4">{title}</h1>
            <h2 className="text-white mb-14">{subtitle}</h2>
          </div>
        </div>
      </div>
      <div className="full-width-strip bg-accuRankerCream">
        <div className="max-w-fit bg-accuRankerCream w-full py-12 mx-5 md:m-auto px-4 xl:px-0">
          <div className="max-w-7xl flex flex-col gap-11 m-auto xl:px-0">
            <div className="mt-4 mb-4 flex flex-col md:flex-row justify-between">
              <div className="flex flex-col gap-4 w-full">
                <h2 className="font-sans">Open Positions</h2>
                {OpenPosition && OpenPosition.job_positions.length > 0 ? (
                  <div className="flex flex-col gap-0">
                    {OpenPosition.job_positions.map((job, idx) => (
                      <div>
                        {idx === 0 ? (
                          <TopLines className="mx-10" />
                        ) : (
                          <Lines className="mx-10" />
                        )}
                        <JopPositionRow {...job} />
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>
                    Sorry, there are no open positions right now. But we’re
                    always looking for new talent to join our development,
                    marketing and sales teams. If you’re looking for an exciting
                    workplace with people who are passionate about what they do,{" "}
                    <a
                      className="font-medium text-accuRankerOrange-500"
                      href="www.acurranker.com"
                    >
                      send us
                    </a>{" "}
                    your CV and motivation letter.
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between gap-14 font-serif">
              {textblock.map((block, idx) => (
                <div key={idx} className="flex flex-col gap-4">
                  <h2 className="font-sans">{block.title}</h2>
                  <span>{block.subtitle}</span>
                </div>
              ))}
            </div>
            <div className="w-full">
              <BannerWrapper>
                <div className="flex flex-col gap-6 text-white text-center justify-center items-center">
                  <h2 className="text-white">
                    <MDXRenderer>{mdxBody(bannerTitle)}</MDXRenderer>
                  </h2>
                  <p className="w-3/4">{banner.text}</p>
                  <Button color="orange">
                    <Link to="/contact/">Contact Us</Link>
                  </Button>
                </div>
              </BannerWrapper>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default CareersPage;
